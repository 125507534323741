import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"background": "--color-light",
	"padding": "50px 0 50px 0",
	"quarkly-title": "Footer-22"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"border-color": "#232a44",
			"md-flex-direction": "column",
			"align-items": "center",
			"lg-flex-direction": "column"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"md-margin": "0px 0px 25px 0px",
			"sm-flex-direction": "column",
			"align-items": "center",
			"lg-margin": "0px 0px 25px 0px",
			"md-flex-direction": "column"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"md-margin": "0px 0px 15px 0px",
			"sm-flex-direction": "column",
			"justify-content": "flex-start",
			"align-items": "center",
			"grid-gap": "24px"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"grid-gap": "8px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--darkL2",
			"children": "Телефон:"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "tel:096 285 5014",
			"color": "--darkL2",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"display": "block",
			"margin": "0px 0px 0px 0px",
			"sm-margin": "0px 0 5px 0px",
			"hover-color": "#6d32ec",
			"children": "096 285 5014"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"grid-gap": "8px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--darkL2",
			"children": "E-mail:"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "maito:burgomistr@",
			"color": "--darkL2",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"display": "block",
			"margin": "0px 0px 0px 0px",
			"hover-color": "#6d32ec",
			"children": "burgomistr@opamakinst.com"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"grid-template-columns": "repeat(5, 1fr)",
			"grid-gap": "16px 24px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--darkL2",
			"children": "Burgomistr © 2023 All rights reserved."
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Box {...override("box3")}>
						<Text {...override("text")} />
						<Link {...override("link")} />
					</Box>
					<Box {...override("box4")}>
						<Text {...override("text1")} />
						<Link {...override("link1")} />
					</Box>
				</Box>
			</Box>
			<Box {...override("box5")}>
				<Text {...override("text2")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;